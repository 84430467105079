<template>
  <div>
    <TitleMore
      :title="['红十字智库简介']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard trainCard">
      <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;
text-align:center">{{introData.title}}</p>
      <TitleLine width="200px"></TitleLine>
      <p class="trainContentCard">
        {{introData.metaDescription}}
      </p>
    </div>
    <TitleMore
      :title="['红十字智库信息（以姓氏笔画为序）']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <div
        class="introCard"
        v-for="item in ListData"
        :key="item.id"
      >
        <img
          v-if="item.smallImage"
          style="width:180px;height:250px"
          :src="item.smallImage+'!180x250'"
          alt=""
        >
        <div class="bossInfo">
          <p class="name">{{item.title}}</p>
          <div class="bossInfoHint">
            <p class="bossInfoHint_p">
              <img
                src="../../assets/img/post.png"
                alt=""
                style="margin-right:10px"
              >
              <span>专业：{{item.major}}</span>
            </p>
            <p class="bossInfoHint_p">
              <img
                src="../../assets/img/post.png"
                alt=""
                style="margin-right:10px"
              >
              <span>单位：{{item.unit}}</span>
            </p>
          </div>
          <div style="
font-size: 16px;
color: #666666;line-height:30px" v-html="item.metaDescription"></div>
        </div>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="size"
        :total="total"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      introData: "",
      page: 1,
      size: 10,
      total: 0,
      ListData: [],
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getIntro();
    this.getList();
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getIntro() {
      apiUrls.trainNum({ node: "hszhzkjj" }).then((res) => {
        this.introData = res.results.data;
      });
    },
    getList() {
      apiUrls
        .selfDynamicList({ node: "hszhzkxx", page: this.page - 1, size: this.size })
        .then((res) => {
          this.ListData = res.results.data;
          this.total = res.results.pager.totalElements
        });
    },
  },
};
</script>
<style lang="less" scoped>
.trainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .trainContentCard {
    width: 1038px;
    height: auto;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 0px #fad5d6;
    font-size: 16px;
    color: #666666;
    line-height: 36px;
    padding: 20px 40px;
    background: url("../../assets/img/bg-left.png") no-repeat;
    background-size: 20% 130%;
  }
}
.introCard {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  border-bottom: 1px dotted #e5e5e5;
  .bossInfo {
    width: calc(100% - 200px);
    margin-left: 20px;
    padding-top: 20px;
    .name {
      font-size: 24px;
      font-weight: bold;
      color: #d72829;
    }
    .bossInfoHint {
      margin: 20px 0px 10px 0px;
      display: flex;
      .bossInfoHint_p {
        margin-right: 20px;
      }
    }
  }
}
</style> 